
export type Pos = {
  x: number;
  y: number;
};

export type Vect = {
  deltaX: number;
  deltaY: number;
};

export type Snake = {
  body: Pos[];
  direction: number;
  directionVector: Vect;
  size: number;
  length: number;
  bodyColor: {
    r: number;
    g: number;
    b: number;
  };
};

export const getRandom = (min: number, max: number) => {
  const diff = Math.abs(max - min);
  return Math.random() * diff + min;
};

export  const nextColorComp = (comp: number, inc: number) => {
  let c = comp + inc;
  if (c > 255) c = c - 255;
  if (c < 0) c = c + 255;
  return c;
};

export const createSnake = (
  minX: number,
  maxX: number,
  minY: number,
  maxY: number
) => {
  const size = getRandom(5, 20);
  const direction = Math.random() * 2 * Math.PI;
  const directionVector = {
    deltaX: Math.cos(direction),
    deltaY: Math.sin(direction),
  };

  const snake: Snake = {
    body: [{ x: getRandom(minX, maxX), y: getRandom(minY, maxY) }],
    direction,
    directionVector,
    size: size,
    length: getRandom(50, 100),
    bodyColor: {
      r: Math.random() * 255,
      g: Math.random() * 255,
      b: Math.random() * 255,
    },
  };

  return snake;
};

export const changeRandomDirection = (snake: Snake) => {
  turnSnake(snake, getRandom(-0.3, 0.3));
};

export const turnSnake = (snake: Snake, turn: number) => {
  snake.direction += turn;
  snake.directionVector = {
    deltaX: Math.cos(snake.direction),
    deltaY: Math.sin(snake.direction),
  };
};
export const updatePosition = (
  snake: Snake,
  minX: number,
  maxX: number,
  minY: number,
  maxY: number
) => {
  if (snake.body.length >= snake.length) snake.body = snake.body.splice(1);

  const headPos = snake.body[snake.body.length - 1];
  let posX = headPos.x;
  let posY = headPos.y;

  posX += (snake.directionVector.deltaX * snake.size) / 2;
  posY += (snake.directionVector.deltaY * snake.size) / 2;

  if (posX >= maxX - snake.size) posX = minX + snake.size;
  else if (posX <= minX + snake.size) posX = maxX - snake.size;
  else if (posY >= maxY - snake.size) posY = minY + snake.size;
  else if (posY <= minY + snake.size) posY = maxY - snake.size;

  snake.body.push({ x: posX, y: posY });
};

