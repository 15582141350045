import React, { useCallback, useEffect, useState } from "react";
import {
  Snake,
  createSnake,
  updatePosition,
  changeRandomDirection,
  turnSnake,
  nextColorComp,
} from "./snake";

const margin = 50;
const numberOfSnakes = 10;
const minX = margin / 2;
const minY = margin / 2;
const maxX = window.innerWidth - margin / 2;
const maxY = window.innerHeight - margin / 2;
const snakes: Snake[] = [];
const masterSnake = createSnake(minX, maxX, minY, maxY);

for (let i = 0; i < numberOfSnakes; i++)
  snakes.push(createSnake(minX, maxX, minY, maxY));

export const SnakesAlive = () => {
  const [, setCounter] = useState<number>(0);

  const doSimulationTimeStepCB = useCallback(() => {
    snakes.forEach((snake) => {
      updatePosition(snake, minX, maxX, minY, maxY);
      changeRandomDirection(snake);
    });
    updatePosition(masterSnake, minX, maxX, minY, maxY);
    updatePosition(masterSnake, minX, maxX, minY, maxY);
    setCounter((count) => count + 1);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      doSimulationTimeStepCB();
    }, 50);
    return () => clearInterval(interval);
  }, [doSimulationTimeStepCB]);

  const handleKeyPress = (event: any) => {
    const snake = snakes[0];

    if (event.key === ",") {
      turnSnake(masterSnake, -0.3);
    } else if (event.key === ".") {
      turnSnake(masterSnake, 0.3);
    }

    snake.directionVector = {
      deltaX: Math.cos(snake.direction),
      deltaY: Math.sin(snake.direction),
    };
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div>
      <svg
        key="snake-board"
        width={window.innerWidth}
        height={window.innerHeight}
      >
        <rect
          key="snake-boarder"
          width="100%"
          height="100%"
          style={{
            fill: "black",
            stroke: "silver",
            strokeWidth: margin,
            opacity: 1,
          }}
        ></rect>

        {<RenderMasterSnake snake={masterSnake} />}
        {snakes.map((s, idx) => (
          <RenderSnake key={idx} snake={s} />
        ))}
      </svg>
    </div>
  );
};

const RenderSnake = ({ snake }: { snake: Snake }) => {
  let r = snake.bodyColor.r;
  let g = snake.bodyColor.g;
  let b = snake.bodyColor.b;
  return (
    <>
      {snake.body.map((seg, idx) => (
        <circle
          cx={seg.x}
          cy={seg.y}
          r={snake.size}
          style={{
            fill: `rgb(${(r = nextColorComp(r, 7))}, ${(g = nextColorComp(
              g,
              -10
            ))}, ${(b = nextColorComp(b, 13))})`,
            strokeWidth: 0,
          }}
        />
      ))}
    </>
  );
};

const RenderMasterSnake = ({ snake }: { snake: Snake }) => {
  return (
    <>
      {snake.body.map((seg, idx) => (
        <circle
          cx={seg.x}
          cy={seg.y}
          r={snake.size}
          style={{
            fill: idx % 2 ? "rgb(0, 0, 0)" : "rgb(255, 231, 10)",
            strokeWidth: 0,
          }}
        />
      ))}
    </>
  );
};
